html {
    scroll-behavior:smooth;
  }
.Main {
    padding-top: 90px;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .fixed_nav {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 90px;
        background: white;

        h1 {
            width: 50%;
            display: flex;
            justify-content: space-around;
            a {
                color: black;
                text-decoration: none;

                &:hover {
                    color: rgb(167, 0, 0);
                }
            }
        }
    }

    .body {
        padding-top: 90px;
        .left_menu_column {
            width: 100%;
    
            .left_menu_column_main {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                .img_block {
                    width: 33%;
                    height: auto;
                    overflow: hidden;
                    margin-bottom: 20px;
        
                    .img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .right_menu_column {
            width: 100%;

            .right_menu_column_main {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                .img_block {
                    width: 33%;
                    height: auto;
                    overflow: hidden;
                    margin-bottom: 20px;
        
                    .img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        
        .hookah_menu_column {
            width: 100%;
            padding-top: 30px;
            .hookah_menu_column_main {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                .img_block {
                    width: 33%;
                    height: auto;
                    overflow: hidden;
                    margin-bottom: 20px;
        
                    .img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 992px) {
    .Main {
        width: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    
        .fixed_nav {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 90px;
            background: white;
    
            h1 {
                width: 100%;
                display: flex;
                justify-content: space-around;
                a {
                    font-size: 17px;
                    color: black;
                    text-decoration: none;
    
                    &:hover {
                        color: rgb(167, 0, 0);
                    }
                }
            }
        }
    
        .body {
            padding-top: 50px;
            .left_menu_column {
                width: 100%;
        
                .left_menu_column_main {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
        
                    .img_block {
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        margin-bottom: 20px;
            
                        .img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
    
            .right_menu_column {
                width: 100%;
    
                .right_menu_column_main {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
        
                    .img_block {
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        margin-bottom: 20px;
            
                        .img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .hookah_menu_column {
                width: 100%;
    
                .hookah_menu_column_main {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
        
                    .img_block {
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        margin-bottom: 20px;
            
                        .img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
    
        }
    }
}